<template>
    <div class="page list-page">

        <div class="page--list" v-loading="loading">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <list-tool-bar>
                    <el-button v-if="auth.export"
                        @click.stop="handleExportBtn"
                    >导出业务权益</el-button>
                </list-tool-bar>
                <list-search-bar  @search.stop="handleSearch" @clear.stop="handleClearBtn">
                    <el-form-item label="客户编号" label-width="72px">
                        <el-input v-model.trim="SP_.custCode" @keyup.native.enter.stop="handleInputEnter"
                            class="width2"></el-input>
                    </el-form-item>
                    <el-form-item label="订单编号" label-width="72px">
                        <el-input v-model.trim="SP_.orderNo" @keyup.native.enter.stop="handleInputEnter"
                            class="width2"></el-input>
                    </el-form-item>

                </list-search-bar>
                <el-row class="mt4" :gutter="20">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl ="24">
                        <div class="flex a-center j-between hw_mt16 hw_pl15 hw_pr15 tipsBox hw_f12 h36">
                            <div class="flex a-center">
                                <img src="../../../assets/images/info-circle.png" alt="">
                                <span class="hw_ml5">温馨提示：自然年度内每投资50w发放一份权益。</span>
                            </div>
                           
                        </div>

                        <div></div>
                    </el-col>
                </el-row>
                

                <el-table :data="tableData">
                    <el-table-column label="客户编号" prop="custCode" width="120px"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime" min-width="100px"></el-table-column>
                    <el-table-column label="订单编号" prop="orderNo" min-width="100px"></el-table-column>
                    <el-table-column label="订单金额" prop="oerderMoney" min-width="80px"></el-table-column>
                    <el-table-column label="权益份数" prop="rightNumber" min-width="80px"></el-table-column>
                    <el-table-column label="备用积分" prop="integer" min-width="80px"></el-table-column>
                    <el-table-column label="备注" prop="remark" min-width="100px"></el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="SP.pageNum"
                    :page-size="SP.pageSize" :total="total" @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
import forList from "@/mixin/forList";

export default {
	mixins: [forList],
	data() {
		return {
			SP_: {
				custCode: "",     // 
                orderNo: "", // 
			},
            tableData:[]
		};
	},

	methods: {

        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "api/integer/queryrightList",
					method: "post",
					data: {
                        ...{ // 参数不足会报错，，
							custCode: "",     // 
							orderNo: "", // 
                        },
                        ...this.SP
                    }
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.integerList;
					
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},
         // 导出
         async handleExportBtn () {
			let loadingRef;
			try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();
             
                const res = await this.$axios({
                    url: "/api/integer/downloadright",
                    method: "post",
					data:{
                        custCode:this.SP_.custCode,     // 
						orderNo: this.SP_.orderNo, // 
                    }
                })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },
	},
    created () {
		
		this.auth.export = this.$hasAuthFor("/api/integer/downloadright");
	}
};
</script>

<style lang="scss">
.tipsBox {
    background: rgba(253, 154, 23, 0.1);
    border: 1px solid rgba(253, 154, 23, 0.5);
    border-radius: 2px;
}
</style>
