<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                
                <el-row style="margin-bottom:15px;">
                    <el-col :span="6">
                        <div class="flex a-center">
                            <span>客户编号：</span>
                             <el-input style="width:220px;" v-model.trim="custCode"></el-input>
                        </div>
                    </el-col>
                  
                    <el-col :span="10">
                        <div class="flex a-center">
                            <el-select style="width:150px;" class="hw_mr5" v-model="searchTimeType" slot="prepend" placeholder="请选择">
                                <el-option v-for="item in searchTimeTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                            <el-date-picker
                            v-model="searchBeginTime"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                            </el-date-picker>
                            <div class="hw_ml5 hw_mr5">-</div>
                            <el-date-picker
                            value-format="yyyy-MM-dd"
                            v-model="searchEndTime"
                            placeholder="选择日期">
                            </el-date-picker>
                        </div>
                    </el-col>
                    
                    <el-button style="margin-left:24px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                    
                   
                        <el-button 
                            @click.stop="handleExportBtn"
                            v-if="auth.export"
                        >导出积分日志</el-button>
                   
                </el-row>
                <el-table :data="tableData">
                  
                    <el-table-column label="订单编号" prop="orderNo" align="left"></el-table-column>
                    <el-table-column label="客户编号" prop="custCode" align="left"></el-table-column>
                    <el-table-column label="客户姓名" prop="custname" align="left"></el-table-column>

                    <el-table-column label="等级积分" prop="vipMoney" align="left"></el-table-column>
                    <el-table-column label="发放积分" prop="grantMoney" align="left"></el-table-column>
                    <el-table-column label="办理业务费用" prop="cash" align="left"></el-table-column>
                    <el-table-column label="创建时间" prop="createTime" align="left">
                        <template slot-scope="scope">
                           <div v-html="scope.row.createTime"></div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="发放时间" prop="grantTime" align="left"></el-table-column>
                    <el-table-column label="是否发放" prop="isSend" align="left"></el-table-column> -->
                    <el-table-column label="备注" prop="change_desc" align="left" min-width='200'></el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            custCode:'',
            searchTimeType:1,
            searchBeginTime:'',
            searchEndTime:'',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            searchTimeTypeList: [
                { name: "创建时间", value: 1},
            ],
            auth:{
                export:false
            }
        };
    },
    created() {
        
        this.auth.export = this.$hasAuthFor("/api/integer/downloadBillDatail");
        if(this.$route.params.noticeType>-1){
            this.noticeType = Number(this.$route.params.noticeType) 
        }
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
      
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.custCode=''
            this.searchTimeType=1
            this.searchBeginTime=''
            this.searchEndTime=''
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/order/queryIntegralLogsList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    custCode: this.custCode,
                    searchTimeType:this.searchTimeType,
                    searchBeginTime:this.searchBeginTime,
                    searchEndTime:this.searchEndTime,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.data || [];
                this.total = res.data.count || 0;
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
        // 导出
        async handleExportBtn () {
            if (!this.searchBeginTime){
                return this.$message({
					message: "请选择时间范围",
					type: "warning"
				});
            }
				

                console.log('this.searchBeginTime', this.searchBeginTime)
                console.log('this.searchEndTime', this.searchEndTime)


			let loadingRef;
			try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();
             
                const res = await this.$axios({
                    url: "/api/integer/downloadBillDatail",
                    method: "post",
					data:{
                        custCode: this.custCode,
                        searchTimeType:this.searchTimeType,
                        searchBeginTime:this.searchBeginTime,
                        searchEndTime:this.searchEndTime
                    }
                })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },
    },
};
</script>
<style lang="scss" scoped>
</style>