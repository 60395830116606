// 基础设置

export default {
    path: "/basic",
    name: "Basic",
    component: () =>
        import(
            /* webpackChunkName: "basic" */
            "../views/_1_basic/basic.vue"
        ),
    title: "基础设置",
    icon: "basic",

    children: [
        {
            path: "dictionary",
            name: "Dictionary",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/dictionary/dictionary.vue"
                ),
            title: "数据字典",
            descreption: "数据字典",
        },
        {
            path: "whiteList",
            name: "WhiteList",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/whiteList/whiteList.vue"
                ),
            title: "白名单",
            descreption: "白名单",
        },
        {
            path: "organization",
            name: "Organization",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/organization/organization.vue"
                ),
            title: "组织架构",
        },
        {
            path: "customerSource",
            name: "CustomerSource",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/customerSource/customerSource.vue"
                ),
            title: "客户来源",
        },
        {
            path: "logs",
            name: "Logs",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/logs/logs.vue"
                ),
            title: "操作日志",
        },
        {
            path: "notify",
            name: "notifySetting",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/_1_basic/notifySetting/notifySetting.vue"
                ),
            title: "消息设置",
        },
        {
            path: "memberBusiness",
            name: "memberBusiness",
            component: () =>
                import(
                    "../views/_1_basic/memberBusiness/index.vue"
                ),
            title: "会员业务券",
        },
    ],
};