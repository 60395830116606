import { render, staticRenderFns } from "./channelList.vue?vue&type=template&id=be65efd0&scoped=true"
import script from "./channelList.js?vue&type=script&lang=js&external"
export * from "./channelList.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be65efd0",
  null
  
)

export default component.exports