import forList        from "@/mixin/forList";
import ExportPayments from "./ExportPayments.vue";
import ExportInCome from "./ExportInCome.vue";
import ExplainStates  from "./ExplainStates.vue";
import AddPayments    from "./AddPayments.vue";
import UpdateState    from "./UpdateState.vue";
import UpdateDates    from "./UpdateDates.vue";
import UpdateChannel  from "./UpdateChannel.vue";
import UpdateFollowUp from "./UpdateFollowUp.vue";
import CheckSeason    from "./CheckSeason.vue";
import ApplyChannel   from "./ApplyChannel.vue";
import UpdateManager  from "./UpdateManager.vue";
import UpdateExpire   from "./UpdateExpire.vue";

import {
	searchTypeEnum,
	dateTypeEnum,
	payStateEnum,
	// fileStateEnum,
	expireStateEnum,
    actionList,
    searchCheckarr,
    exportInComeTypeEnum
} from "./config";

// 将数字转换为美式表示方式
const formatNumber = function (val) {
    if ((typeof val) !== "number") return "";
    const _val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return _val;
}

export default {
	name: "OrderList",
	noFetchFirst: true,
	mixins: [forList],
	components: {
		ExportPayments,
        ExportInCome,
		ExplainStates,
		AddPayments,
		UpdateState,
		UpdateDates,
		UpdateChannel,
		UpdateFollowUp,
		CheckSeason,
		ApplyChannel,
		UpdateManager,
        UpdateExpire,
    },
    computed: {

    },

	data() {
		return {
			searchTypeEnum,       // 模糊查询字段选项
			dateTypeEnum,         // 供查询的日期类型选项
			enumIsReady           : false, // 以下几个远程的选项数据是否已经获得
			regionList            : [], // 区域选项     name, id
			departmentEnum        : [], // 部门小组选项
            queueDept: [], //小队
			sourceTypeList        : [], // 客户来源选项 name, id
			childSourceTypeList   : [], // 下级客户来源选项
			OrderTypeEnum         : [], // 签约芬类选项
			// OrderAddStateEnum     : [], // 增投选项
			OrderStateEnum        : [], // 订单状态选项
			payTypeList           : [], // 支付方式选项 name, type
			moneyTypeList         : [], // 币种选项     name, type
			seasonList            : [], // 赛季选项     name, id
			OrderLaterStateEnum   : [], // 到期状态选项
			WorkDeptTypeEnum      : [], // 业务部门选项
			OrderAddStateEnum     : [], // 增投类型选项
			payStateEnum,         // 回款状态选项
			// fileStateEnum,        // 资料收集状态选项
			expireStateEnum,      // 是否到期选项
            searchCheckarr,//是否核算业绩
            exportInComeTypeEnum,
			// subSourchTitle: "",   // 下级客户来源的标题
			SP_: {
				searchType          : 1,
				searchValue         : "",
				searchTimeType      : 1,
				searchStartTime     : "",
				searchEndTime       : "",

				searchRegin         : [], // 搜索区域
				searchDept          : [], // 搜索部门
                searchQueue: [],// 搜索小队

				searchSource        : [], // 搜索来源
				searchChildSource   : [], // 搜索来源子集
				searchOrderType     : [], // 签约芬类
				searchOrderChildType: [], // 签约芬类
                searchState: 0, // 搜索状态
				searchPayState      : "", // 回款状态
				// searchFileState   : "", // 资料状态
				searchExpireState   : "", // 订单是否到期
                searchWorkDeptType: [],
                searchCheck: '', //是否核算业绩
			},
			searchHrefType        : "", // 快捷搜索标识：1待提交佣金申请 2需补录状态订单
			searchCommPayState:'',

			totalMap              : {}, // 业绩统计
			
			showExportPayments    : false, // 弹出导出缴费的日期选择窗
            showExportInCome: false, // 弹出导出订单收入窗

			showExplain           : false, // 订单状态说明
			updatingItem          : null,  // 给变更订单状态的操作窗传递订单数据，并弹出
			payingItem            : null,  // 给添加缴费的操作窗传递订单数据，并弹出
			updatingDates         : null,  // 通知打款的订单
			updatingChannel       : null,  // 修改渠道结佣的订单
			updatingFollowUp      : null,  // 补录到期状态的订单
			seasonItem            : null,  // 核算赛季的订单
			applyChannelItem      : null,  // 申请渠道结佣的订单
			showManagerPop        : false, // 批量修改维护员工
			showExpirePop         : false, // 批量修改到期日期

            showCheckAchievementDia: false,//批量核算业绩奖金

            fileList: [],
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            uploading: null,
            showCommissionDia: false,
            showTip: true,
            showTip2: true,
            performanceCalData: '',
            moneyMap: {},

            clickRow: '',
            showOrderState: false, //赎回明细弹窗
            stateTableData: [],
		};
	},

	created () {
		this.auth.save                   = this.$hasAuthFor("api/order/saveOrder");
		this.auth.addPayment             = this.$hasAuthFor("api/order/saveOrderPay");
		this.auth.updateState            = this.$hasAuthFor("api/order/updateOrderState");
		this.auth.detail                 = this.$hasAuthFor("api/order/queryOrderDetails");
		this.auth.updateDates            = this.$hasAuthFor("api/order/noticePayTime");
		this.auth.updateChannel          = this.$hasAuthFor("api/order/updateOrderChanComm");
		this.auth.updateFollowUp         = this.$hasAuthFor("api/order/updateOrderLaterState");
		this.auth.commitCommission       = this.$hasAuthFor("api/orderComm/saveOrderCommApply");
		this.auth.checkSeason            = this.$hasAuthFor("api/order/checkOrderSeason");
		this.auth.applyChannelCommission = this.$hasAuthFor("api/orderChanComm/saveOrderChanCommApply");
		this.auth.delete                 = this.$hasAuthFor("api/order/deleteOrder");
		this.auth.manager                = this.$hasAuthFor("api/order/updateServiceEmp");
		this.auth.expire                 = this.$hasAuthFor("api/order/updateFinishTime");
		this.auth.exportOrder            = this.$hasAuthFor("api/order/downOrder");
        this.auth.exportInCome = this.$hasAuthFor("api/order/downOrderIncome");

		this.auth.exportPayment          = this.$hasAuthFor("api/order/downOrderPay");
        this.auth.before = this.$hasAuthFor("api/order/advanceSendIntegral");
        this.auth.batchCheckAchievement = this.$hasAuthFor("api/order/batchCheckAchievement");


		actionList.forEach(action => {
			action.banned = !this.auth[action.name]
		});

		const {params} = this.$route;
		if (Object.keys(params).length) {
			for (let key in params) {
				if (key === "searchHrefType") this.searchHrefType = params[key];
				else if (key === "searchCommPayState") this.searchCommPayState = params[key];
				else if (Object.prototype.hasOwnProperty.call(this.SP_,key))
					this.SP_[key] = params[key]
			}
			this.showAdvanceSearch = true;
			this.handleSearch();
		}
        else {
            this.handleSearch();
        }
	},

	methods: {
        // 点击复制
        copyCode(code) {
            var input = document.createElement("input"); // 创建input对象
            input.value = code; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.closeAll();
            this.$message.success('复制成功！');
        },
        submitUpload() {
            if (this.fileList.length == 0) {
                this.$message({
                    message: "请选择上传文件",
                    type: "warning"
                });
            } else {
                // this.uploading = this.$loading();

                this.uploading = this.$loading({
                    target: ".circulation-money .el-dialog"
                    // target: ".circulation-money"
                });
                console.log(1123);
                this.$refs.upload.submit();
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileChange(files) {
            this.fileList = [files]
        },
        handleUploadSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.fileList = []
                }, 20);
            }
            if (res.code == 2000) {
                this.$message({
                    message: '上传成功，核算结果已自动导出',
                    type: "success"
                });
                setTimeout(() => {
                    this.$refs.upload.clearFiles();
                    this.fileList = []
                    this.showCheckAchievementDia = false
                }, 500);
                setTimeout(() => {
                    if (res.data.returnFile) {
                        window.open(this.$baseURL + '/' + res.data.returnFile, "download");
                    }
                }, 2000);
            }
        },

        clearSP_trim () {
            this.SP_.searchState = 0,
            this.SP_.searchType = 1,
            this.SP_.searchTimeType = 1
        },

		async fetchData(noloading) {
            // console.log('这里');
            // regionList  departmentEnum queueDept
			let loading;
			if (noloading !== true) loading = this.$loading();

			try {
				this.fetchOrderSummary();
				if (!this.enumIsReady) {
					await this.fetchEnum();
				}
					
				const res = await this.$axios({
					url: "/api/order/queryOrderList",
					method: "post",
					data: {
						...this.extractParams(),
						searchHrefType: this.searchHrefType,
						searchCommPayState:this.searchCommPayState
					}
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.orderList.map(it=>{
						it.bili = it.bili+"%";
						it.oldProductId = (it.oldProductId||"").split(",").map(Number).filter(Boolean);
						const _actionList = [...actionList];

						//#region 根据订单状态设置一些操作菜单权限
						const updateFollowUpIndex = _actionList.findIndex(({name}) => 
							name === "updateFollowUp");
						const updateFollowUpItem = _actionList[updateFollowUpIndex];
						_actionList[updateFollowUpIndex] = {
							...updateFollowUpItem,
							/**
							 * todo
							 * 仅订单到期后可补录到期状态
							 */
							banned: !it.finishFlasg
						};
						//#endregion

                        var isReady = it.bili.replace("%", "");
                        if (Number(isReady) < 100) {
                            const deleteActionIndex1 = _actionList.findIndex(({ name }) => name == "checkSeason");
                            if (deleteActionIndex1 >= 0) _actionList.splice(deleteActionIndex1, 1)
                        }

						it.actionList = _actionList;
						return it;
					});
					this.tableData = list;
					this.searchHrefType = "";
					this.searchCommPayState = "";

					loading?.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading?.close();
			}
		},
		async fetchOrderSummary () {
			try {
				this.totalMap = {};
				const res = await this.$axios({
					url: "/api/order/getOrderTotal",
					method: "post",
					data: {
						...this.extractParams(),
						searchHrefType: this.searchHrefType
					}
				});
	
				if (res.code === 2000) {
					this.totalMap = {
						payTotal: formatNumber(res.data?.totalMap.payTotal),
						contractMoneyTotal: formatNumber(res.data?.totalMap.contractMoneyTotal)
					}
	
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("业绩统计失败", reason)
			}
		},
		async fetchEnum () {
			const res = await this.$axios({
				url: "/api/order/queryOrderParam",
				method: "post"
			});

			if (res.code === 2000) {
				for (let key in res.data) {
					this[key] = res.data[key];
				}
                this.enumIsReady = true;
                this.OrderStateEnum.unshift({ name: "全部", value: 0 })
                let _index = this.OrderStateEnum.findIndex(item => {  //4.0.1 不要清退了
                    return item.value == 5
                })
                if (_index >= 0) {
                    this.OrderStateEnum.splice(_index, 1)
                }
                // 详情等页面回到列表页时 回显 部门和小队
                if (this.SP_.searchRegin.length == 1) {
                    this.fetchDepartmentEnum(this.SP_.searchRegin[0]);
                }
                if (this.SP_.searchDept.length == 1) {
                    this.fetchQueueEnum(this.SP_.searchDept[0]);
                }

			} else if (res.code !== 1003)
				throw res
		},

		// 点击高级搜索
		handleGJBtn () {
			if (this.showAdvanceSearch) 
				this.closeAdvanceSearch()
			else this.handleAdvancedSearchBtn()
		},

		handleSearchReginChange (e) {
			this.SP_.searchDept = [];
			this.departmentEnum = [];

            this.SP_.searchQueue = [];
            this.queueDept = []
			if (e.length == 1) this.fetchDepartmentEnum(e[0]);
		},
		async fetchDepartmentEnum (pid) {
			// const loading = this.$loading({
			// 	target: ".search-region"
			// });

			try {
				const res = await this.$axios({
					url: "/api/employee/queryOrgByPId",
					method: "post",
					data: {pid}
				});

				if (res.code === 2000) {
					this.departmentEnum = res.data.regionList;
					// loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				// loading.close();
			}
		},
        handleSearcQueueChange(e) {
            this.SP_.searchQueue = [];
            this.queueDept = []
            if (e.length == 1) this.fetchQueueEnum(e[0]);
        },
        async fetchQueueEnum(pid) {
            try {
                const res = await this.$axios({
                    url: "/api/employee/queryOrgByPId",
                    method: "post",
                    data: { pid }
                });

                if (res.code === 2000) {
                    this.queueDept = res.data.regionList;
                    // loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                // loading.close();
            }
        },

		handleSearchSourceChange (e) {
			this.SP_.searchChildSource = [];
			this.childSourceTypeList   = [];
			if (e.length == 1) {
				const pid = e[0]
				// this.subSourchTitle = this.sourceTypeList.find(
				// 	it => it.id === pid).name
				this.fetchChildSourceTypeList(pid);
			// } else {
			// 	this.subSourchTitle = "";
			}
		},
		async fetchChildSourceTypeList (pid) {
			// const loading = this.$loading({
			// 	target: ".search-source"
			// });

			try {
				const res = await this.$axios({
					url: "/api/sourceType/querySourceTypeByPid",
					method: "post",
					data: {pid}
				});

				if (res.code === 2000) {
					this.childSourceTypeList = res.data.sourceTypeList;
					// loading.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				// loading.close();	
			}
		},

		handleSearchOrderTypeChange (e) {
			this.SP_.searchOrderChildType = [];
		},
        async downLoadfile() {
            let loadingRef,
                url = '/api/order/downloadCheckTemplat'
            loadingRef = this.$loading();

            const res = await this.$axios({
                method: "post", url, data: {}
            })
            console.log(res);
            if (res.code === 2000) {
                // this.$message({
                //     message: "下载成功",
                //     type: "success"
                // });
                if (res.data.path) {
                    window.open(this.$baseURL + '/' + res.data.path, "download");
                }
                loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },

        // 点击导出订单
        async handleExportBtn (flag) {
			if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
				return this.$message({
					message: "请选择时间范围",
					type: "warning"
				});

			let loadingRef,
				url = flag === 1 ? "/api/order/downOrderPay" :
					"/api/order/downOrder";
			try {
                await this.$confirm("确定导出吗？", "系统提示")
				loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },


        // 点击批量核算业绩奖金
        handleCheckAchievementDiaBtn() {
            this.showCheckAchievementDia = true
            setTimeout(() => {
                this.$refs.upload.clearFiles();
                this.fileList = []
            }, 20);

        },
        cancelCommissionDia() {
            this.showCommissionDia = false
        },

        cancelCheckAchievement() {
            this.showCheckAchievementDia = false
            this.$refs.upload.clearFiles();
            this.fileList = []
        },

		// 点击导出缴费
		handleExportPaymentsBtn () {
            // this.showExportPayments = true
			this.handleExportBtn(1)
		},
        // 点击导出订单收入
        handleExportInComeBtn() {
            this.showExportInCome = true
            // this.handleExportBtn(1)
        },

		// 点击: 订单状态说明
		handleExplainBtn () {
			this.showExplain = true
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push({
				path: "/core/order/add",
				query: {sys_id: this.SP_.search_sys}
			});
		},

		// 点击 表格行的操作按钮
        handleAction (action, scope) {
            this.clickRow = scope.row
            const type = action.name;
            const data = scope.row;
			switch (type) {
				// case "save":
				// 	this.$router.push({
				// 		path: `/core/order/edit?id=${data.id || 5}`,
				// 		query: {sys_id: data.sys_id}
				// 	});
				// 	break;

                case "save":
                    this.$router.push({
                        path: `/core/eoi/edit?id=${data.id || 5}`,
                        query: { sys_id: data.sys_id }
                    });
                    break;


				case "addPayment":
					if (!data.noticePayTimeName) {
						this.$alert(
                            "通知打款后方可添加回款记录",
							"提示",
							{
								confirmButtonText: "关闭"
							}
						);
						return;
					}
					this.payingItem = data;
					break;
				
				case "updateState":
					this.updatingItem = data;
					break;

				case "detail":
					this.$router.push({
						path: `/core/order/detail/${data.id || 5}`
					});
					break;
				
				case "updateDates":
					this.updatingDates = data
					break;
				
				case "updateChannel":
					this.updatingChannel = data;
					break;
				
				case "updateFollowUp":
					this.updatingFollowUp = data;
					break;
				
				case "commitCommission":
					/**
					 * 提交审核时验证资料齐日期，通知打款日期，回款10%日期是否为空，
					 * 如存在日期为空的则提示“暂未达到业绩审核标准，请检查相关日期的
					 * 填写”，审核通过与审核中的订单不允许在提交业绩审核，点击提示“订
					 * 单业绩审核中，请勿重复提交”。仅审核不通过的可再次提交业绩审核。
					 */

                    // var {allFileTimeName, noticePayTimeName, sumPayAllTime} = data;
					// console.log(data, allFileTimeName, noticePayTimeName, sumPayAllTime);
                    // var isReady = (!!allFileTimeName && !!noticePayTimeName && !!sumPayAllTime);

                    var isReady = data.bili.replace("%", "");
                    if (Number(isReady) < 100 || !data.allGainTime) {
						this.$alert(
                            "暂未达到业绩审核标准，请检查相关内容是否完整并全款齐到账。",
							"订单提交业绩审核",
							{
								confirmButtonText: "关闭"
							}
						);
					}
					else {
						this.commitCommission(data)
					}

					break;
				
				case "checkSeason":
					this.seasonItem = data;
					break;
				
				case "applyChannelCommission":
					this.applyChannelItem = data;
					break;
				
				default:
					console.log("有没有操作处理程序");
					break;
			}
        },
        commitCommission(data) {
            this.showTip = true
            this.queryPerformanceCalData(data)
            this.showCommissionDia = true
        },

        // 
        async queryPerformanceCalData(data) {
            let loadingRef = this.$loading({
                target: ".commitCommission .el-dialog"
            });

            try {
                const res = await this.$axios({
                    url: "/api/order/queryOrderSaleAndCoopInfo",
                    method: "post",
                    data: {
                        type: 0, //0：提交绩效审核 1：薪酬审核
                        orderId: data.id
                    }
                })
                if (res.code === 2000) {
                    this.performanceCalData = res.data.data
                    this.moneyMap = res.data.moneyMap
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
		// 提交业绩审核
        async commitCommissionAfter(item) {
			let loadingRef;
			try {
                // await this.$confirm("确定要提交业绩审核么", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/orderComm/saveOrderCommApply",
					method: "post",
					data: {
                        orderId: this.clickRow.id
					}
				})
                this.showCommissionDia = false

				const duplication = res.msg == "业绩审核已存在，请勿重复提交";
				if (res.code === 2000 || duplication) {
					loadingRef && loadingRef.close();

					const jump = await this.$confirm(
						duplication ? "订单业绩审核中，请勿重复提交" : "订单已提交审核，请注意审核进度！",
						"订单提交业绩审核",
						{
							confirmButtonText: "去查看进度"
						}
					)
					if (jump) this.$router.push({
                        path: `/commission/orderCommission`
					})
					else this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "提交失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

		// 点击: 批量删除
		async handleBulkDelete() {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/order/deleteOrder",
					method: "post",
					data: {
						orderIds: this.checkedlist.map(
							it => it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

		// 点击: 批量修改维护员工
		async handleBulkManager() {
			this.showManagerPop = true;
		},

		// 点击: 批量修改到期日期
		async handleBulkExpire() {
			this.showExpirePop = true;
        },
        // 点击: 提前发放积分
        async beforeJF() {
            // 一次只能提前发放一个全款齐的订单积分，需要二次确认
            let loadingRef;
            try {
                await this.$confirm("确定要提前发放积分吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/advanceSendIntegral",
                    method: "post",
                    data: {
                        orderId: this.checkedlist.map(
                            it => it.id).join()
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "发放成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "发放失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        async openOrderState(row) {
            this.stateTableData = []
            this.fetchStateTableData(row)
            this.showOrderState = true;
        },
        async fetchStateTableData(row) {
            // let loading;
            // loading = this.$loading();

            const loading = this.$loading({
                target: ".showOrderState .el-dialog"
            })
            try {


                const res = await this.$axios({
                    url: "/api/order/queryRedeemDetail",
                    method: "post",
                    data: {
                        orderId: row.id
                    }
                });

                if (res.code === 2000) {
                    res.data.redeemList.forEach(it => {
                        it.createTime = it.createTime?.replace(" ", "<br>")
                    });
                    this.stateTableData = res.data.redeemList || []
                    loading?.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading?.close();
            }
        },

	}
};