import forList from "@/mixin/forList";

// 将数字转换为美式表示方式
const formatNumber = function (val) {
    if ((typeof val) !== "number") return "";
    const _val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return _val;
}

const todoList = [
    {
        id: 6,
        label: "待分公司运营审核EOI",
        prop: "branchCompanyNum",
    },
    {
        id: 7,
        label: "待总公司审核EOI",
        prop: "allCompanyNum",
    },
    // {
    //     id: 8,
    //     label: "待通知翻译",
    //     prop: "noticeTranslateNum",
    // },
    {
        id: 8,
        label: "待审核全套资料",
        prop: "checkFileNum",
    },
    {
        id: 9,
        label: "待完成翻译",
        prop: "completeTranslateNum",
    },
    {
        id: 10,
        label: "待通知打款",
        prop: "noticePaymentNum",
    },

    // {
    //     id: 1,
    //     label: "即将到期订单",
    //     prop : "soonNum",
    // },
    {
        id: 2,
        label: "待提交业绩申请",
        prop : "commNum",
    },
    {
        id: 3,
        label: "待中国区运营审核业绩",
        prop : "chCommNum",
    },
    {
        id: 4,
        label: "待澳洲区运营审核业绩",
        prop : "azCommNum",
    },
    {
        id: 5,
        label: "需补录状态订单",
        prop : "laterStateNum",
    }
];
const shortcut = [
    {
        id: 4,
        // label: "EOI列表",
        label: "全部订单",
        path: "/core/eoi",
        prop: "eoiList"
    },
    {
        id: 1,
        label: "订单管理",
        path : "/core/order",
        prop : "orderList"
    },
    {
        id: 2,
        label: "佣金结算表",
        path: "/commission/orderCommission",
        prop : "commissionList"
    },
    {
        id: 3,
        label: "员工管理",
        path : "/core/staff",
        prop : "staffList"
    },
    {
        id: 5,
        label: "消息列表",
        path: "/messages/messageList",
        prop: "findMessagePage"
    },
];
const countList = [
    {
        id: 1,
        label: "签约数",
        prop : "createNum",
        icon : require("./images/contrac.png")
    },
    {
        id: 2,
        label: "全款齐订单数",
        prop : "allMoneyNum",
        icon : require("./images/order.png")
    },
    {
        id: 3,
        label: "签约总金额",
        prop : "tatolMoney",
        icon : require("./images/amout.png")
    },
    {
        id: 4,
        label: "回款总金额",
        prop : "payTatolMoney",
        icon : require("./images/money.png")
    }
];
const auth = {
    soonNum       : false, // 即将到期
    commNum       : false, // 待提交佣金
    chCommNum     : false, // 中国区审核
    azCommNum     : false, // 澳洲区审核
    laterStateNum : false, // 需补录状态
    orderList     : false, // 订单管理
    commissionList: false, // 佣金结算表
    staffList     : false, // 员工管理

    eoiList: false,//eoi列表
    findMessagePage: false,//消息通知列表
    branchCompanyNum: false,//待分公司审核数量
    allCompanyNum: false,//待总公司审核数量
    checkFileNum: false, //待审核全套资料数量
    noticeTranslateNum: false,//待通知翻译数量

    completeTranslateNum: false,//待完成翻译
    noticePaymentNum: false,//待通知打款


    // createNum     : false, // 签约数
    // allMoneyNum   : false, // 全款齐订单数
    // tatolMoney    : false, // 签约总金额
    // payTatolMoney : false, // 回款总额
}

// 初始化统计日期
let searchStartTime = "",
    searchEndTime   = "";
(function () {
    const today = new Date();
    let yyyy = today.getFullYear(),
        MM = today.getMonth() + 1,
        endDate;
    if (MM === 2) {
        if (yyyy % 4) endDate = 29;
        else endDate = 28
    }
    else {
        const shortMonth =  [4, 6, 9, 11].includes(MM);
        if (shortMonth) endDate = 30;
        else endDate = 31;
    }
    MM = MM > 9 ? MM : ("0" + MM);
    searchStartTime = `${yyyy}-${MM}-01`;
    searchEndTime   = `${yyyy}-${MM}-${endDate}`;
})();

export default {
    name: 'Home',
    noSaveState : 1,
    noFetchFirst: true,
	mixins: [forList],

    data() {
        return {
            auth,
            showloginOut: false,
            showWelcom  : true,
            todoList,
            todoMap     : {
                laterStateNum: 0, // 需补录状态订单
                chCommNum    : 0, // 待中国区审核佣金
                azCommNum    : 0, // 待澳洲区审核佣金
                soonNum      : 0, // 即将到期订单
                commNum      : 0, // 待提交佣金申请
                branchCompanyNum: 0,//待分公司审核数量
                allCompanyNum: 0,//待总公司审核数量
                noticeTranslateNum: 0,//待通知翻译数量 
                checkFileNum: 0,//待审核全套资料数量
                completeTranslateNum: 0,//待完成翻译
                noticePaymentNum: 0,//待通知打款

            },
            shortcut,
            SP: {
                searchStartTime,
                searchEndTime
            },
            SP_: {
                searchStartTime: "",
                searchEndTime: "",
            },
            countList,
            countMap       : {
                createNum    : 0, // 签约数
                tatolMoney   : 0, // 签约总金额
                allMoneyNum  : 0, // 全款齐订单数
                payTatolMoney: 0, // 回款总金额
            },
        }
    },

    created () {
        if (!this.userInfo.pathList) return;
        this.showloginOut = !!this.$route.query.type;

        // 市场方向的岗位 不显示统计数据 仅显示欢迎语
        const isMarketing = this.userInfo?.postDir == undefined && this.userInfo?.postDir === 3;
        if (!isMarketing) {
            // 非市场方向的岗位 显示统计主页
            this.showWelcom   = this.userInfo?.postDir === 3;
            this.updateSPtoSP_();
            this.getTodoList();
            this.getCountList();
        }

        // 初始化权限
        this.auth.soonNum = this.$hasAuthFor("/core/order");
        this.auth.commNum        = this.$hasAuthFor("api/orderComm/saveOrderCommApply");
        this.auth.chCommNum      = this.$hasAuthFor("api/orderComm/updateOneCheckState");
        this.auth.azCommNum      = this.$hasAuthFor("api/orderComm/updateTwoCheckState");
        this.auth.laterStateNum  = this.$hasAuthFor("api/order/updateOrderLaterState");
        this.auth.orderList = this.$hasAuthFor("/core/order");
        this.auth.commissionList = this.$hasAuthFor("/commission/orderCommission");
        this.auth.staffList = this.$hasAuthFor("/core/staff");

        this.auth.branchCompanyNum = this.$hasAuthFor("/messages/branchOfficeList");
        this.auth.allCompanyNum = this.$hasAuthFor("/messages/headOfficeList");


        this.auth.noticeTranslateNum = this.$hasAuthFor("api/orderProcess/saveBuyOrder");
        this.auth.checkFileNum = this.$hasAuthFor("api/orderProcess/checkInvest"); //待审核全套资料
        this.auth.completeTranslateNum = this.$hasAuthFor("api/orderProcess/saveTranslateTime");
        this.auth.noticePaymentNum = this.$hasAuthFor("api/orderProcess/saveNoticePayTime");

        this.auth.eoiList = this.$hasAuthFor("/core/eoi");
        this.auth.findMessagePage = this.$hasAuthFor("/messages/messageList");
    },

    methods: {
        formatNumber,
        // 获取待办数据
        async getTodoList () {
            const loading = this.$loading({
                target: ".part1 .left"
            })
            try {
				const res = await this.$axios({
					url: "/api/index//indexDealOrderNum",
					method: "post",
				});

				if (res.code === 2000) {
                    const data = res.data.numMap;
                    const _data = {};
					Object.keys(data).forEach(key => {
                        _data[key] = formatNumber(data[key])
                    });
                    this.todoMap = _data;

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获待办项失败", reason);
			}
            loading.close();
        },

        // 获取统计数据
        async getCountList () {
            const loading = this.$loading({
                target: ".part2"
            })
            try {
				const res = await this.$axios({
					url: "/api/index/indexOrderNum",
					method: "post",
                    data: this.extractParams(true)
				});

				if (res.code === 2000) {
                    const data = res.data.numTatolMap;
                    const _data = {};
					Object.keys(data).forEach(key => {
                        // console.log(key, data[key]);
                        _data[key] = formatNumber(data[key])
                    });
					this.countMap = _data;

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获统计数据失败", reason);
			}
            loading.close();
        },

        handleTodoItem (item) {
            // 点击代办项
            switch (item.id) {
                case 1:
                    // 即将到期的订单
                    (function () {
                        let today = new Date(), M = today.getMonth();
                        const todayStr = this.getDateStr(today);
                        today.setMonth(M+3);
    
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchTimeType: 2,
                                searchStartTime: todayStr,
                                searchEndTime: this.getDateStr(today),
                                searchState: [1]
                            }
                        });
                    }).call(this)
                    break;

                case 2:
                    // 待提交佣金申请
                    (function () {
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchHrefType: 1,
                                searchPayState: 3,
                                searchCommPayState:1,
                                searchStartTime: "2022-11-01",
                                searchState: [1]
                            }
                        });
                    }).call(this)
                    break;

                case 3:
                    // 待中国区审核佣金
                    (function () {
                        this.$router.push({
                            name: "OrderCommission",
                            params: {
                                searchCheckState: 1
                            }
                        });
                    }).call(this)
                    break;

                case 4:
                    // 待澳洲区审核佣金
                    (function () {
                        this.$router.push({
                            name: "OrderCommission",
                            params: {
                                searchCheckState: 2
                            }
                        });
                    }).call(this)
                    break;

                case 5:
                    // 需补录状态订单
                    (function () {
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchHrefType   : 2,
                                searchExpireState: 2,
                                searchState: [1]
                            }
                        });
                    }).call(this)
                    break;
                case 6:
                    // 待分公司审核EOI
                    (function () {
                        this.$router.push({
                            name: "BranchOfficeList",
                            params: {

                            }
                        });
                    }).call(this)
                    break;
                case 7:
                    // 待总公司审核EOI
                    (function () {
                        this.$router.push({
                            name: "HeadOfficeList",
                            params: {

                            }
                        });
                    }).call(this)
                    break;
                case 8:
                    // 待通知翻译 待审核全套资料数量
                    (function () {
                        this.$router.push({
                            name: "eioList",
                            params: {
                                searchCheckState: [3],
                            }
                        });
                    }).call(this)
                    break;
                case 9:
                    // 待完成翻译
                    (function () {
                        this.$router.push({
                            name: "eioList",
                            params: {
                                searchCheckState: [5],
                            }
                        });
                    }).call(this)
                    break;
                case 10:
                    // 待通知打款
                    (function () {
                        this.$router.push({
                            name: "eioList",
                            params: {
                                searchCheckState: [6],
                            }
                        });
                    }).call(this)
                    break;


                default:
                    break;
            }
        },

		getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
	
				return `${Y}-${M}-${D}`;
			}
			else return ""
		},

        handleShortcut (item) {
            // 点击快捷操作
            this.$router.push({
                path: item.path
            });
        },

        handleCountButton () {
            // 点击统计按钮
            if (!this.SP_.searchStartTime) return this.$message({
                message: "请选择开始时间",
                type: "error"
            })
            if (!this.SP_.searchEndTime) return this.$message({
                message: "请选择结束时间",
                type: "error"
            })
            this.updateSP_toSP();
            this.getCountList();
        },

        handleCountItem (item) {
            // 点击统计条目
            switch (item.id) {
                case 1: // 签约数
                case 3: // 签约总金额
                    (function () {
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchTimeType: 1,
                                searchStartTime: this.SP.searchStartTime,
                                searchEndTime  : this.SP.searchEndTime,
                                searchState    : [1]
                            }
                        });
                    }).call(this)
                    break;

                case 2:
                    // 全款齐订单数
                    (function () {
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchTimeType: 4,
                                searchStartTime: this.SP.searchStartTime,
                                searchEndTime  : this.SP.searchEndTime,
                                searchState    : [1]
                            }
                        });
                    }).call(this)
                    break;

                case 4:
                    // 回款总金额
                    (function () {
                        this.$router.push({
                            name: "OrderList",
                            params: {
                                searchTimeType: 3,
                                searchStartTime: this.SP.searchStartTime,
                                searchEndTime  : this.SP.searchEndTime,
                                searchState    : [1]
                            }
                        });
                    }).call(this)
                    break;

                default:
                    break;
            }
        }
    }
}