<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="china-audit"
        :visible="show"
        @close="handleClose"
        title="订单审核"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="订单审核" prop="checkState">
                <el-radio-group v-model="PB.checkState">
                    <el-radio :label="1">通过</el-radio>
                    <el-radio :label="0">未通过</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="起息日期" v-if="PB.checkState==1"
                prop="interestTime">
                <el-date-picker
                    class="width3"
                    v-model="PB.interestTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="订单到期日期" v-if="PB.checkState==1"
                prop="finishTime">
                <el-date-picker
                    class="width3"
                    
                    v-model="PB.finishTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="实际投资周期" v-if="PB.checkState==1"
                prop="investCycle">
                <el-input-number :controls="false" class="width3" v-model="PB.investCycle" :precision="2" :step="0.1" :max="100"></el-input-number>
                <span class="unit">月</span>
            </el-form-item>
            

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ChinaAudit',
    props: [ "show", "orderlist" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                checkState: "", // 审核状态 1通过 0不通过
                remark    : "",
                interestTime : "",
                investCycle:"",
                finishTime:""

            },
            rules: {
                checkState : {required: true, message: "请选择审核结果"},
                interestTime  : {required: true, message: "请选择起息日期"},
                finishTime  : {required: true, message: "请选择订单到期日期"},
                investCycle  : {required: true, message: "请填写实际投资周期", trigger: 'blur'}
            },
        }
    },
    watch: {
        show (item, old) {
            if(this.show){
                this.queryExamineInterestTime()//查询订单起息日期
            }
        }
    },

    methods: {
        async queryExamineInterestTime() {
            const res = await this.$axios({
                url: "/api/orderComm/queryExamineInterestTime",
                method: "post",
                data:{
                    type:1,
                    orderId:this.orderlist.map(({orderId})=>orderId).join()
                }
            });

            if (res.code === 2000) {
                this.PB.interestTime = res.data.interestTime || ''
                this.PB.investCycle = res.data.investCycle || ''
                this.PB.finishTime = res.data.finishTime || ''
            } else if (res.code !== 1003)
                throw res
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".china-audit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderComm/updateOneCheckState",
                    method: "post",
                    data: {
                        ...this.PB,
                        applyIds: this.orderlist.map(({id})=>id).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.checkState  = "";
            this.PB.remark      = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }

    /deep/ .el-input-number .el-input__inner{
        text-align: left;
        border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    }

    .unit{
        text-align: center;
        background-color: #f5f7fa;
        color: #909399;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 0 20px;
        /* width: 1px; */
        white-space: nowrap;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        height: 30px;
    }
</style>